.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


footer.footer {
  background-color: #363636;
}

ul.list-root {
  padding-top: 8px;
  padding-bottom: 8px;
  margin: 0;
  padding: 0;
  position: relative;
  list-style: none;
}

li.list-item {
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  justify-content: flex-start;
  text-decoration: none;
  padding-left: 16px;
  padding-right: 16px;
}

.list-item-text {
  flex: 1 1 auto;
  min-width: 0;
  margin-top: 4px;
  margin-bottom: 4px;
}

.list-item-icon {
  min-width: 56px;
  flex-shrink: 0;
}

.arrow-svg {
  fill: #00d1b2 !important
}

article.box {
  background-color: #4a4a4a;
}

.social-media-svg {
  fill: hsl(219, 70%, 96%) !important;
}

.active {
  background-color: #292929 !important;
  border-bottom: 1px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-style: dashed;
  border-color: #00d1b2;
}

.navbar-start a.navbar-item:active {
  background-color: #292929 !important;
}

.navbar-menu.is-active {
  background-color: #4a4a4a;
}

.navbar-menu a.navbar-item {
  background-color: inherit !important;
}